import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import Layout from "../components/layout";
import SEO from "../components/seo";
import {
  innerWidth,
  mainCyan,
  lightPurple,
  screen,
} from "../components/variables";
import { formatDate } from "../helpers";
import FaqRow from "../components/faq-row";

const Wrapper = styled.div`
  .inner-wrapper {
    max-width: ${innerWidth};
    margin: 0 auto 110px auto;
    padding: 0 32px;
    @media ${screen.xxsmall} {
      padding: 0 42px;
    }
    @media ${screen.medium} {
      padding: 0;
    }

    .heading {
      font-weight: 900;
      line-height: 1.22;
      position: relative;
      font-size: 2rem;
      @media ${screen.xxsmall} {
        font-size: 3.5rem;
      }
      @media ${screen.small} {
        font-size: 5rem;
        line-height: 1.09;
      }
    }
  }

  .subwrapper {
    max-width: 1030px;
    margin: 40px 0 0 0;
    @media ${screen.small} {
      margin: 70px auto 0 auto;
    }

    .date {
      font-size: 1.03rem;
      font-weight: 700;
      @media ${screen.small} {
        font-size: 1.07rem;
      }
    }

    .description {
      margin: 26px 0 0 0;
      @media ${screen.xxsmall} {
        margin: 35px auto 0 auto;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-weight: 700;
        line-height: 1.4;
        margin: 38px 0 16px 0;
        font-size: 1.25rem;
        @media ${screen.xxsmall} {
          font-size: 1.52rem;
          margin: 38px 0 23px 0;
        }
        @media ${screen.small} {
          font-size: 1.55rem;
        }
      }

      p {
        margin: 0 0 21px 0;
        font-size: 1.02rem;
        @media ${screen.small} {
          font-size: 1.06rem;
        }
        &:last-child {
          margin: 0;
        }
      }

      a {
        color: ${mainCyan};
        text-decoration: underline;
        @media ${screen.withCursor} {
          &:hover {
            color: ${lightPurple};
          }
        }
      }
    }
  }
`;

const PrivacyPage = ({ data }) => {
  const { heading, description } = data.content.data;

  const last_publication_date = data.content.last_publication_date;

  const pageURL = `https://www.k9swim.com.au/privacy/`;

  return (
    <Layout>
      <SEO title={heading.text} url={pageURL} />
      <Wrapper>
        <div className="inner-wrapper">
          <h1 className="heading">{heading.text}</h1>

          <div className="subwrapper">
            <p className="date">
              Last updated: {formatDate(last_publication_date)}
            </p>

            <div
              className="description"
              dangerouslySetInnerHTML={{ __html: description.html }}
            />
          </div>
        </div>

        <FaqRow />
      </Wrapper>
    </Layout>
  );
};

export default PrivacyPage;

export const dataQuery = graphql`
  {
    content: prismicPrivacyPolicyPage {
      last_publication_date
      data {
        heading {
          text
        }
        description {
          html
        }
      }
    }
  }
`;
