import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import {
  mainPurple,
  mainWhite,
  mainOrange,
  innerWidth,
  screen,
} from "../../components/variables";

const Wrapper = styled.section`
  background: ${mainOrange};
  color: ${mainWhite};
  text-align: center;
  padding: 60px 0;
  @media ${screen.xxsmall} {
    padding: 78px 0;
  }
  @media ${screen.small} {
    padding: 87px 0;
  }

  .faq-inner-wrapper {
    max-width: ${innerWidth};
    margin: 0 auto;
    padding: 0 32px;
    @media ${screen.xsmall} {
      padding: 0 42px;
    }
    @media ${screen.medium} {
      padding: 0;
    }
  }

  .faq-heading {
    font-weight: 900;
    line-height: 1.22;
    font-size: 1.87rem;
    @media ${screen.xxsmall} {
      font-size: 2.5rem;
    }
    @media ${screen.small} {
      font-size: 3rem;
      line-height: 1.19;
    }
  }

  .faq-description {
    margin: 10px 0 0 0;
    font-size: 1.25rem;
    line-height: 1.45;
    @media ${screen.xxsmall} {
      font-size: 1.9rem;
      margin: 2px 0 0 0;
    }
    @media ${screen.small} {
      font-size: 2.3rem;
    }

    .break {
      display: block;
      @media ${screen.xxsmall} {
        display: inline;
      }
    }

    a {
      color: ${mainWhite};
      font-weight: 700;
      text-decoration: underline;
      @media ${screen.withCursor} {
        &:hover {
          color: ${mainPurple};
        }
      }
    }
  }
`;

const FaqRow = () => {
  return (
    <Wrapper>
      <div
        className="faq-inner-wrapper"
        data-sal="fade"
        data-sal-duration="700"
      >
        <h4 className="faq-heading">Have questions?</h4>
        <p className="faq-description">
          Head over to our{" "}
          <span className="break">
            <Link to="/faqs/" aria-label="Frequently asked questions page">
              FAQ's page
            </Link>{" "}
            for answers!
          </span>
        </p>
      </div>
    </Wrapper>
  );
};

export default FaqRow;
